import React from "react"
import styled from "styled-components"
import closeBlack from "../../white-co-images/close-black.svg"
import { PracticeOverlayCard } from "./PracticeOverlayCard"
import { disableBodyScroll, enableBodyScroll, clearAllBodyScrollLocks } from 'body-scroll-lock';

const data = [
  {
    title: "Wytes",
    subtitle: "Westcombe Park",
    address: "13 Station Crescent, Westcombe Park,",
    postCode: "SE3 7EQ",
    tel: "020 8853 3304",
    link: "https://uk.dentalhub.online/soe/new/Westcombe%20Park%20Dental%20Practice?pid=UKANP04",
    isCommingSoon: false,
  },
  {
    title: "Wytes",
    subtitle: "Elephant and Castle",
    address: "94-96 Walworth Road, London,",
    postCode: "SE1 6SW",
    tel: "020 7703 2524",
    link: "https://uk.dentalhub.online/soe/new/Wytes%20Elephant%20%26%20Castle?pid=UKANP07",
    isCommingSoon: false,
  },
  {
    title: "Wytes",
    subtitle: "West Wickham",
    address: "88 Manor Park Road, West Wickham,",
    postCode: "BR4 0JZ",
    tel: "020 8777 4754",
    link: "https://uk.dentalhub.online/soe/new/Manor%20Park%20Dental%20Practice?pid=UKANP02",
    isCommingSoon: false,
  },
  // {
  //   title: "Manor Park",
  //   subtitle: "One Dental Care",
  //   address: "1 Beckenham Road, West Wickham, Bromley,",
  //   postCode: "BR4 0QR",
  //   tel: "020 8777 1977",
  //   link: "https://uk.dentalhub.online/soe/new/Manor%20Park%20Dental%20Practice?pid=UKANP02",
  //   isCommingSoon: false,
  // },
]

interface PracticeOverlayInterface {
  isOpen: boolean;
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  href?: string
}

export const PracticeOverlay:React.FC<PracticeOverlayInterface> = ({isOpen, setIsOpen, href}) => {
  const onClose = () => {
    const targetElement = document.querySelector('#modal-scroll-lock-selector');
    if(targetElement === null) return
    enableBodyScroll(targetElement);
    setIsOpen(false)
  }
  return (
    <Container isOpen={isOpen} id={"modal-scroll-lock-selector"}  >
      <Background />

      <Card >
        <AlignRight>
          <CloseContainer onClick={onClose}>
            <Close src={closeBlack} />
          </CloseContainer>
        </AlignRight>
        <Title>Select a practice</Title>
        <ContainerGrid>
          {data.map(v => {console.log(href);
            return (<PracticeOverlayCard
              key={v.subtitle}
              title={v.title}
              subtitle={v.subtitle}
              address={v.address}
              postCode={v.postCode}
              tel={v.tel}
              link={v.link}
              isCommingSoon={v.isCommingSoon}
            />)
          })}
        </ContainerGrid>
        <BottomContainer >
            <button onClick={onClose}>
              CANCEL
            </button>
          </BottomContainer>
      </Card>
    </Container>
  )
}

const ContainerGrid = styled.div`
  display: grid;
  grid-gap: 30px;
  grid-template-columns: 1fr 1fr 1fr;
  padding-left: 60px;
  padding-right: 60px;
  padding-bottom: 30px;
  font-family: Roboto, sans-serif;
  @media (max-width: 1023px) {
    grid-template-columns: 1fr ;
    padding-left: 25px;
  padding-right: 25px;
  grid-gap: 10px;
  }

`

const Container = styled.div`
  position: fixed;
  display: ${props => props.isOpen ? "block" :"none" } ; 
  z-index: 9999999;
  /* background-color: black; */
  top: 0;
  overflow-y: scroll;
  left: 0;
  right: 0;
  height: 100%;
`

const Background = styled.div`
  height: 100%;
  width: 100%;
  background-color: black;
  position: absolute;
  opacity: 0.4;
`

const Card = styled.div`
position: relative;
  background-color: white;
  position: relative;
  max-width: 993px;
  margin-top: 100px;
  @media (max-width: 1023px) {
    margin-top: 0px;
  }
  padding-top: 21px;
  /* z-index: 10001; */
  margin-left: auto;
  margin-right: auto;
`
const AlignRight = styled.div`
  display: flex;
  flex-direction: row;
  padding-right: 30px;
  @media (max-width: 1023px) {
    padding-right: 25px;
  }
  justify-content: end;
`
const CloseContainer = styled.div`
 cursor: pointer;
  width: 38px;
  height: 38px;
  border: 3px black solid;
  border-radius: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BottomContainer = styled.div`

  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 30px;
  &> button {

    border: black 1px solid;
    min-width: 210px;
    padding-top: 15px;
    padding-bottom: 15px;
    background-color: white;
    cursor: pointer;
  }
`

const Close = styled.img`
  padding:5px;
`

const Title = styled.h2`
  font-size: 48px;
  line-height: 58px;
  font-family: Roboto, sans-serif;
    font-weight: 300;
  text-align: center;
  padding-bottom: 30px;
  @media (max-width: 1023px) {
    font-size: 26px;
    line-height: 36px;
    padding-bottom: 24px;
  }
`
