import React from "react"
import styled from "styled-components"

interface PracticeOverlayCardInterface {
  title: string
  subtitle: string
  address: string
  postCode: string
  tel: string
  link: string
  isCommingSoon: boolean
}

export const PracticeOverlayCard: React.FC<PracticeOverlayCardInterface> = ({
  title,
  subtitle,
  address,
  postCode,
  tel,
  link,
  isCommingSoon,
}) => {
  return (
    <Container href={link !== "" ? link : null} target={"__blank"}>
        <TitleContainer>
            <Title>{title}</Title>
            <Subtitle>{subtitle}</Subtitle>
            {isCommingSoon ? <ButtonContainer><ComingSoon>Coming Soon</ComingSoon></ButtonContainer> : ""}
        </TitleContainer>
        <TextCenter>

      <Address>{address}{" "}</Address>
      <Postcode>{postCode}</Postcode>
        </TextCenter>
      <PhoneNumber>{tel}</PhoneNumber>
      <ButtonContainer>
        <Button><span>SELECT</span></Button>
      </ButtonContainer>
    </Container>
  )
}

const TextCenter = styled.div`
text-align: center;
font-family: Roboto, sans-serif;
    font-weight: 300;
    margin-bottom: 14px;
    @media (max-width: 1023px) {
      margin-bottom: 8px;
  }
`
const ButtonContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
`

const TitleContainer = styled.div`
    min-height: 102px;
    height: 102px;
    
    margin-bottom: 29px;
    @media (max-width: 1023px) {
      min-height: auto;
      height: fit-content !important;
      margin-bottom: 19px;
    height: 61px;
    display: block;
    /* max-width: 300px; */
    text-align: center;
  }

`

const Container = styled.a`
  padding-left: 22px;
  padding-right: 22px;
  padding-top: 30px;
  padding-bottom: 30px;
  border: 2px solid #ECECEC;
  color:#061B30;
  text-decoration: none;
  transition: all 0.2s;
  &:link {
    text-decoration: none;
    color:#061B30;
    
  }
  &:visited {
    text-decoration: none;
    color:#061B30;
    
  }
  &:hover{
      border: 2px solid #49DDC2;
      
  }
  @media (max-width: 1023px) {
    /* padding-bottom: 53px; */
  }
`
const Title = styled.h3`
    font-size: 24px;
    line-height: 34px;
    color:#061B30;
    font-family: Roboto, sans-serif;
    font-weight: 300;
    text-align: center;
    @media (max-width: 1023px) {
      font-size: 16px;
    line-height: 26px;
    display: inline-block;
  }
`

const Subtitle = styled.h4`
    font-size: 24px;
    line-height: 34px;
    color:#061B30;
    text-align: center;
    /* font-family: headingFontLight; */
    font-weight: 400;
    max-width: 204px;
    text-align: center;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: 1023px) {
      font-size: 16px;
    line-height: 26px;
    display: inline-block;
    padding-left: 5px;
  }

`
const Address = styled.span`
  
    text-align: center;
    margin-right: auto;
    margin-left: auto;
   
    `

const Postcode = styled.span`

padding-bottom: 14px;
text-align: center;

`

const PhoneNumber = styled.p`
    font-weight: bold;
    text-align: center;
    padding-bottom: 23px;
    
`

const Button = styled.button`
    padding-top:10px;
    padding-bottom:10px;
    min-width: 106px;
    min-height: 40px;
    padding-left:7px;
    padding-right:7px;
    border: none;
    margin-right: auto;
    margin-left: auto;
    background-color: #49DDC2;
    & > span{
        min-width: 91px;
        size: 15px;
        color: #061B30;
    }
    @media (max-width: 1023px) {
      min-width: 147px;
  }
`

const ComingSoon = styled.span`
 background-color: #49DDC2;
 color: #061B30;
 margin-top: 14px;
 font-size: 12px;
 font-weight: bold;
 text-transform: capitalize;
 padding-bottom: 4px;
 padding-left: 15px;
 padding-right: 15px;
 border-radius:  12.5px;
 max-height: 25px;
text-align: center;

margin-left: auto;
margin-right: auto;

`
