import React, { useState, useEffect } from "react"
import styled from "styled-components"
import color from "../../style/color"
import { isTablet, isMobile } from "react-device-detect"
import { formatLink } from "../../util/functions"

interface ButtonProps {
  href?: string | null
  onClick?: any
  text: string
  secondary?: boolean
  icon?: any
  target?: boolean
}

const Button = ({
  href = null,
  onClick,
  text,
  secondary,
  icon,
  target,
}: ButtonProps) => {
  const [isActive, toggleActive] = useState(false)
  const [mobileDevice, setMobileDevice] = useState(false)

  useEffect(() => {
    if (typeof navigator !== "undefined") {
      setMobileDevice(isTablet || isMobile)
    }
    toggleActive(false)
  }, [])

  return (
    <Link
      secondary={secondary}
      data-testid="button-sticky"
      href={href}
      icon={icon}
      mobileDevice={mobileDevice}
      onClick={() => onClick && onClick()}
      target={target && "_blank"}
      onMouseEnter={() => {
        if (!mobileDevice) {
          toggleActive(true)
        }
      }}
      onMouseLeave={() => {
        if (!mobileDevice) {
          toggleActive(false)
        }
      }}
      onTouchStart={() => {
        toggleActive(true)
      }}
      onTouchEnd={() => {
        toggleActive(false)
      }}
      isActive={isActive}
    >
      {text}
    </Link>
  )
}

export default Button

interface LinkProps {
  secondary?: boolean
  isActive: boolean
  href: string
  mobileDevice?: boolean
}

const Link: any = styled.a<LinkProps>`
  text-decoration: none !important;
  cursor: pointer;
  font-size: 1.8rem !important;
  line-height: 2.4rem !important;
  letter-spacing: 0.5px;
  height: 7.2rem;
  width: 26rem;
  max-width: 250px;
  display: flex;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: ${props =>
    !props.secondary && !props.mobileDevice
      ? `linear-gradient(to right, ${color.brand.blue} 50%, ${color.brand.white} 50%);`
      : !props.mobileDevice
      ? `linear-gradient(to right, ${color.brand.borderBlue} 50%, ${color.brand.green} 50%);`
      : !props.secondary && props.mobileDevice
      ? `${color.brand.green}`
      : `white`};
  ${props =>
    props.secondary
      ? `border: 1px solid ${color.brand.borderBlue}`
      : `border: 1px solid ${color.brand.blue}`};
  ${props => props.secondary && `right: -1px`};
  background-size: 200% 101%;
  background-position: right bottom;
  transition: background-position 0.3s ease-out;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -webkit-tap-highlight-color: transparent;
  &:hover,
  &:active {
    text-decoration: none;
  }
  &:link,
  &:visited {
    color: white !important;
    color: ${color.brand.blue} !important;
  }
  &:hover,
  &:active {
    background-position: ${props => !props.mobileDevice && "left bottom"};
    color: white !important;
  }
  ${props =>
    props.isActive &&
    !props.mobileDevice &&
    `background-position: left bottom;`}
  ${props =>
    props.isActive &&
    !props.secondary &&
    `border: 1px solid ${color.brand.blue};`}
  @media (max-width: 1199px) {
    transition: ${props =>
      !props.mobileDevice ? "background-position 0.1s linear" : ""};
    background-size: ${props => (!props.mobileDevice ? "200% 110%" : "")};
  }
  @media (max-width: 767px) {
    width: 100%;
    max-width: 100%;
    ${props => (props.secondary ? `left: 1px` : "right: -1px")};
  }

  &:hover,
  &:active {
    background: ${props => (props.mobileDevice ? ` ${color.brand.blue};` : "")};
  }
`
