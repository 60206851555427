import React from "react"
import styled from "styled-components"

interface IFullWidthContainer {
  children?: any
  noPaddingTablet?: boolean
  noPaddingMobile?: boolean
}
const FullWidthContainer = ({
  children,
  noPaddingTablet,
  noPaddingMobile,
  noPaddingLeft,
}: IFullWidthContainer) => {
  return (
    <Container
      noPaddingTablet={noPaddingTablet}
      noPaddingMobile={noPaddingMobile}
      noPaddingLeft={noPaddingLeft}
    >
      {children}
    </Container>
  )
}

export default FullWidthContainer

interface IContainer {
  noPaddingTablet?: boolean
  noPaddingMobile?: boolean
  noPaddingLeft?: boolean
}

const Container = styled.div<IContainer>`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 0 5%;
  margin: 0 auto;
  ${props => props.noPaddingLeft && `padding-left: 0;`}

  @media (max-width: 1199px) {
    padding: ${props => (props.noPaddingTablet ? "0" : "0 3%")};
    ${props => props.noPaddingLeft && `padding-left: 0;`}
  }

  @media only screen and (max-width: 1023px) {
    padding: ${props => (props.noPaddingMobile ? "0" : "0 25px")};
    ${props => props.noPaddingLeft && `padding-left: 0;`}
  }
`
