import React, { useState, useEffect } from "react"
import styled from "styled-components"
import color from "../../style/color"
import { isTablet, isMobile } from "react-device-detect"
import { formatLink } from "../../util/functions"

interface ButtonProps {
  href?: string | null
  onClick?: any
  text: string
  fullWidth?: boolean
  size?: string
  primary?: boolean
  secondary?: boolean
  tertiary?: boolean
  quaternary?: boolean
  formConfirmation?: boolean
  isActive?: boolean
  target?: boolean
}

const Button = ({
  href = null,
  onClick,
  text,
  fullWidth,
  size = "medium",
  primary,
  secondary,
  tertiary,
  quaternary,
  formConfirmation,
  target,
}: ButtonProps) => {
  const [isActive, toggleActive] = useState(false)
  const [mobileDevice, setMobileDevice] = useState(false)

  useEffect(() => {
    if (typeof navigator !== "undefined") {
      setMobileDevice(isTablet || isMobile)
    }
    toggleActive(false)
  }, [])

  return (
    <Link
      data-testid="main-button"
      href={href ? formatLink(href) : null}
      onClick={() => onClick && onClick()}
      fullWidth={fullWidth}
      size={size}
      primary={primary}
      secondary={secondary}
      tertiary={tertiary}
      quaternary={quaternary}
      formConfirmation={formConfirmation}
      mobileDevice={mobileDevice}
      target={target && "_blank"}
      onMouseEnter={() => {
        if (!mobileDevice) {
          toggleActive(true)
        }
      }}
      onMouseLeave={() => {
        if (!mobileDevice) {
          toggleActive(false)
        }
      }}
      onTouchStart={() => {
        toggleActive(true)
      }}
      onTouchEnd={() => {
        toggleActive(false)
      }}
      isActive={isActive}
    >
      <span>{text}</span>
    </Link>
  )
}

export default Button

const Link: any = styled.a<ButtonProps>`
  &,
  &:link,
  &:visited {
    position: relative;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    border: 1.6px solid #fff;
    z-index: 1;
    text-decoration: none !important;
    cursor: pointer;
    text-transform: uppercase;
    letter-spacing: 0.5px;
    font-size: ${props =>
      props.size === "small"
        ? "12px"
        : props.size === "large"
        ? "18px"
        : "15px"} !important;
    line-height: ${props =>
      props.size === "small"
        ? "16px"
        : props.size === "large"
        ? "24px"
        : "20px"} !important;
    height: ${props =>
      props.size === "small"
        ? "40px"
        : props.size === "large"
        ? "60px"
        : "50px"};
    border: ${props =>
      props.primary
        ? `1.6px solid ${color.brand.blue}`
        : props.tertiary
        ? `1.6px solid ${color.brand.blue}`
        : props.quaternary
        ? `1.6px solid white`
        : props.formConfirmation
        ? `1.6px solid ${color.brand.blue}`
        : `1.6px solid ${color.brand.blue}`};
    background-color: ${props =>
      props.primary
        ? color.brand.blue
        : props.tertiary
        ? color.brand.blue
        : props.quaternary
        ? `transparent`
        : props.formConfirmation
        ? color.brand.blue
        : `transparent`};
    color: ${props =>
      props.primary
        ? `${color.brand.white}`
        : props.tertiary
        ? `${color.brand.white}`
        : props.quaternary
        ? `white`
        : props.formConfirmation
        ? "white"
        : `${color.brand.blue}`} !important;
    width: 100%;
    max-width: 320px;
    display: flex;
    position: relative;
    justify-content: center;
    align-items: center;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    -khtml-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-tap-highlight-color: transparent;
    & span {
      z-index: 4;
    }
  }

  &:after {
    content: "";
    position: absolute;
    z-index: -1;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    width: 0%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: ${props =>
      props.isActive &&
      (props.primary
        ? color.brand.white
        : props.tertiary
        ? color.brand.blue
        : props.quaternary
        ? `white`
        : props.formConfirmation
        ? `white`
        : color.brand.blue)};
  }
  &:hover:after,
  &:active:after {
    width: ${props => (props.mobileDevice ? "0%" : "100%")};
    background-color: ${props =>
      props.isActive &&
      (props.primary
        ? color.brand.white
        : props.tertiary
        ? "white"
        : props.quaternary
        ? `white`
        : props.formConfirmation
        ? `white`
        : color.brand.blue)};
  }
  &:hover,
  &:active {
    color: ${props =>
      props.isActive &&
      (props.primary
        ? color.brand.blue
        : props.tertiary
        ? color.brand.blue
        : props.quaternary
        ? color.brand.blue
        : props.formConfirmation
        ? color.brand.blue
        : `white`)} !important;
    border: ${props =>
      props.isActive &&
      (props.primary
        ? `1.6px solid ${color.brand.blue}`
        : props.tertiary
        ? `1.6px solid ${color.brand.blue}`
        : props.quaternary
        ? `1.6px solid white`
        : props.formConfirmation
        ? `1.6px solid white`
        : `1.6px solid ${color.brand.blue}`)};
  }
  @media (max-width: 1199px) {
    &,
    &:link,
    &:visited {
      transition: none !important;
    }
  }
  @media (max-width: 1023px) {
    &,
    &:link,
    &:visited {
      width: 100%;
      max-width: 325px;
      height: ${props =>
        props.size === "small"
          ? "40px"
          : props.size === "large"
          ? "50px"
          : "40px"};
      font-size: 12px !important;
      line-height: 17px !important;
    }
  }

  &:hover,
  &:active {
    ${props =>
      props.mobileDevice &&
      `background-color: 
      ${props.isActive &&
        (props.primary
          ? color.brand.blue
          : props.tertiary
          ? color.brand.blue
          : props.quaternary
          ? `white`
          : props.formConfirmation
          ? `white`
          : color.brand.blue)}; transition: background-color 1s ease;`}
  }
`
