export const color: any = {
  brand: {
    blue: "#000000",
    green: "#C4EDFF",
    blueLight: "#37a1ce",
    orange: "#d84a34",
    grey: "#333333",
    white: "#ffffff",
    orangeActive: "#9F371D",
    borderBlue: "#007EB3",
  },
  background: {
    grey: "#f4f4f4",
    lightGrey: "#fafafa",
    white: "white",
    lemon: "#FDFAF6",
    lightLilac: "#FBF8FE",
    blueLight: "#F4FCFF",
    blueBackground: "#f1fdfa",
    lightPink: "#fff4f9",
    blueCookie: "#d9f7ff",
    logoBanner: "#F0F0F0",
    tableGreen: "rgba(196,237,255,0.25)",
    green10: "rgba(196,237,255,0.1)",
    green20: "rgba(196,237,255,0.22)",
    green60: "rgba(196,237,255,0.6)",
    tableHighlight: "rgba(80, 227, 194, 0.05)",
    finance20: "rgba(196,237,255,0.2)",
  },
  text: {
    primary: "#000000",
    secondary: "#000000",
    green: "#49DDC2",
    legal: "#999999",
    grey: "#5d6770",
    formBlack: "#0D0802",
    link: "#000000",
  },
  brandLight: {
    blue100: "#f5fcff",
    grey: "#e2e2e2",
    blue45: "#37a1ce",
  },
  support: {
    red: "#D0021B",
    green: "#4f9f31",
    yellow: "#f8e71c",
    radioBtnHover: "rgba(6,27,48,0.8)",
  },
  keyline: {
    grey: "#e5e5e5",
    lineGreen: "#02ae86",
    black: "#101820",
    treatmentHeader: "#35485b",
    nav: "rgba(230,230,230,.3)",
    green: "#1C2F42",
    rangeTreatmentBorder: "#9CE1FF",
    financeTableBorder: "#D4F1FE",
  },
}

export default color
