import React, { useEffect, useState } from "react"
import "./layout.css"
import "../style/animateTooth.css"
import "../style/globalAnimations.css"
import CookieBar from "../shared/CookieBar/CookieBarDrupal"
import StickyBtnsContainer from "../shared/StickyButtons/StickyButtonsDrupal"

const Layout = ({ children, hideStickyButtons }) => {
  const [cookiesClosed, setCookiesClosed] = useState(false)
  const closeCookies = () => {
    setCookiesClosed(!cookiesClosed)
  }

  return (
    <div>
      <CookieBar closeCookies={closeCookies} />
      {children}
      {!hideStickyButtons && (
        <StickyBtnsContainer cookieClosed={cookiesClosed} />
      )}
    </div>
  )
}

export default Layout
